<template>
    <ion-app>
        <!-- <loading v-if="!loaded" /> -->
        <router-view v-if="loaded"/>
    </ion-app>
</template>

<script lang="ts">
export interface Permission {
    name: string;
}

import { IonApp } from '@ionic/vue';
import { 
    // AxiosError, 
    AxiosResponse 
} from 'axios';
import { computed, defineComponent, nextTick, onMounted, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from "vuex";
import { AxiosKey } from './symbols';
import { injectStrict } from './utils/injectTyped';
import metaData from '@/utils/composables/metaData';
import externalScriptLoader from '@/utils/composables/externalScriptLoader';

// import Loading from '@/components/utility/Loading.vue';

export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        // Loading
    },
    setup() {
        const publicURLs = reactive([
            'login',
            'forgotten-password',
            'password-reset'
        ]);
        const route = useRoute();
        const isPublicRoute = computed(() => publicURLs.indexOf(route.name as string) != -1);

        const store = useStore();
        const isLoggedIn = computed(() => store.getters.isLoggedIn);

        const loaded = ref(false);
        const axios = injectStrict(AxiosKey);
        const { addScript } = externalScriptLoader();
        onMounted(() => {
            axios.get('/api/v1/init').catch(() => {
                axios.get('sanctum/csrf-cookie').then(() => {
                    checkIfUserIsLoggedIn();
                })
            }).then(() => {
                checkIfUserIsLoggedIn();
            })

            addScript('https://js.stripe.com/v3/', 'stripe-js', () => {
                console.log('stripe loaded');
            })

        })

        const router = useRouter();
        const checkIfUserIsLoggedIn = () => {
            if( isPublicRoute.value ) {
                loaded.value = true;
                return;
            }
            axios.get('/api/user').then((response: any)=> {
                if(response.data.isCustomer) {
                    router.push({name: 'login'})
                    return;
                }                
                getUserPermissions();
                store.dispatch('loggedIn',true);
            }).catch( ( /* e: AxiosError */ ) => {
                // console.log(e);
                store.dispatch('loggedIn',false);
            }).finally(()=> {
                // if they are logged in then we proceed. else we redirect to the login page
                // BUT only redirect if they aren't already on the login page
                nextTick(()=> {
                    if(isLoggedIn.value) {
                        loaded.value = true;
                    } else if ( !isPublicRoute.value ) {
                        router.push({name: 'login', query: { r: route.name as string }})
                        loaded.value = true;
                    }
                })
            })
        }

        const getUserPermissions = () => {
            store.dispatch('clearPermissions');
            // axios call to get permissions data for loading UI faster
            axios.get('api/v1/auth/user-can').then((response: AxiosResponse) => {
                // console.log('permissions',response.data);
                addAllPermissions(response.data as any);
                loaded.value = true;
            })
            // .catch(e => console.log(e));
        }

        const addAllPermissions = (permissions: Permission[]): void => {
            // loops through all permissions and adds them to local storage for easy access
            permissions.forEach(permission => store.dispatch('addPermission',permission.name));
        }

        watch(() => isLoggedIn.value, (newValue: boolean) => {
            if(newValue) {
                getUserPermissions();
            }
        })

        const { initMeta } = metaData();
        onMounted(() => {
            initMeta();
        })

        return {
            loaded,
            isPublicRoute,
        }
    },
});
</script>